import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

import { StyledTextModule } from '../TextModule/TextModule.style';
import { StyledValuesModule } from '../Values/Values.style';

export const StyledLearnPage = styled.div`
  .learn__hero {
    ${StyledTextModule} {
      margin: ${pxtorem(28)} 0;
      padding-inline: ${pxtorem(18)};
      @media (min-width: 768px) {
        margin: ${pxtorem(58)} 0;
        padding-inline: ${pxtorem(58)};
      }
    }
  }

  .ingredients {
    @media (max-width: 767px) {
      background: var(--gray);
    }
  }

  .pull-quote-full {
    .pull-quote--content h2 {
      @media (min-width: 768px) {
        font-size: ${pxtorem(56)};
      }
    }
  }

  .featured-articles {
    padding-top: ${pxtorem(20)};
  }

  .values {
    ${StyledValuesModule} {
      .values__mediaShape {
        svg {
          width: auto;
          height: 100%;
          max-height: 151px;
        }
      }
      .values__cta {
        min-width: 170px;

        @media (max-width: 767px) {
          width: auto;
        }
      }
    }
  }
`;
