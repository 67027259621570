import React from 'react';
import {
  PageSection,
  ComponentMapExtraData,
  InstanceMapExtraData,
} from '../PageSection/PageSection';

import { Section } from '../../styles/Section.styled';
import { Wrapper } from '../../styles/Wrapper.styled';
import { ContentfulPageSection } from '../../types/types';

import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';

import { TextModule } from '../TextModule/TextModule';

import { StyledLearnPage } from './LearnPage.style';

const sectionTypesProps: ComponentMapExtraData = {
  ingredients: {
    wrapperProps: { width: 'wide' },
  },
  'text-big': {
    sectionProps: { bg: 'gray' },
    wrapperProps: { width: 'wide' },
  },
  'value-prop-grid': {
    sectionProps: { gutter: true, bg: 'gray' },
    wrapperProps: { width: 'normal', bg: 'white' },
  },
  'pull-quote-full': {
    sectionProps: { bg: 'white' },
    wrapperProps: { width: 'wide' },
    componentProps: { cardColor: 'white' },
  },
  'featured-articles': {
    sectionProps: { bg: 'gray' },
    wrapperProps: { width: 'normal' },
  },
  values: {
    sectionProps: { bg: 'gray', gutter: true },
    wrapperProps: { bg: 'white', width: 'normal' },
  },
  faq: {
    sectionProps: { bg: 'gray', gutter: true },
    wrapperProps: { width: 'normal' },
  },
  'featured-protocols': {
    sectionProps: { bg: 'gray', gutter: true },
    wrapperProps: { bg: 'lime', width: 'normal' },
  },
};

const sectionInstancesProps: InstanceMapExtraData = {};

export const LearnPage: React.FC<LearnPageProps> = ({ hero, sections }) => {
  return (
    <StyledLearnPage>
      <Section className="learn__hero" gutter>
        <ShowOnScroll>
          <Wrapper bg="lime" width="wide" gutter={false}>
            <TextModule section={hero} />
          </Wrapper>
        </ShowOnScroll>
      </Section>

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData = {
            ...(sectionTypesProps[section.sectionType.slug] || {}),
            ...(sectionInstancesProps[section.slug] || {}),
          };

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledLearnPage>
  );
};

interface LearnPageProps {
  hero: ContentfulPageSection;
  sections: ContentfulPageSection[];
}
