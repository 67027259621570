import { graphql } from 'gatsby';
import React from 'react';
import { PageSection } from '~/components/PageSection/PageSection';
import { ContentfulPageSection } from '~/types/types';
import Layout from '../components/Layout/Layout';
import { LearnPage } from '../components/LearnPage/LearnPage';
import Metadata from '../components/Metadata';

const Learn: React.FC<LearnProps> = ({ data }) => {
  const { page } = data;
  return (
    <Layout>
      <Metadata title={page?.name} />
      <LearnPage hero={page.hero} sections={page.sections} />
    </Layout>
  );
};

export interface LearnProps {
  data: {
    page: {
      name: string;
      hero: ContentfulPageSection;
      sections: ContentfulPageSection[];
    };
  };
}

export const query = graphql`
  query LearnPageQuery {
    page: contentfulPage(slug: { eq: "learn" }) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;

export default Learn;
